import logo from './logo.svg';
import './App.css';

function App() {
  return (
      <div className="App">
        <img
            src={`${process.env.PUBLIC_URL}/pencilwhite.png`}
            alt="Pencil White Logo"
            className="centered-image"
        />
      </div>
  );
}

export default App;
